import React, { useEffect, useState, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fNumber, fCurrency } from '../../utils/formatNumber';
import moment from 'moment'

// mui
import { DataGrid, GridToolbar, getGridStringOperators } from '@mui/x-data-grid';
import {Grid,Button,Typography, Autocomplete, FormControl, TextField} from '@mui/material'

import { ReportState, loadRateOptionsAsync, getBatchReportAsync} from './reportSlice'
import Spinner from '../../layouts/Spinner'

const Label = ({ text }) => {
    return <Typography variant={'body1'} style={{color: "#7f8c8d" }}>
        {text}
    </Typography>
}

const Separator = () => {
    return <Grid item xs={12} >&nbsp;</Grid>
}

const prepareLineItems =  (batch) => {
    const batchLines = batch.map((b) => {
        const id = b.id || ''
        const batchId = b.BatchIds[0]?.batchId || ''
        const patientRate = b.Rate.display || ''
        const createdAt = b.createdAt || ''
        const lastName = b.lastName || ''
        const firstName = b.firstName || ''
        const birthDate = b.birthDate || ''
        const intialQueryReturn = b.PatientQueries[0]?.DataSourcePatientQuery?.recordCount || ''
        const intialQueryRate = b.PatientQueries[0]?.Rate.display || ''
        const intialQueryRateAmount = b.PatientQueries[0]?.Rate.rate || ''
        const finalQueryReturn = b.PatientQueries[b.PatientQueries?.length - 1]?.DataSourcePatientQuery?.recordCount || ''
        const finalQueryRate = b.PatientQueries[b.PatientQueries?.length - 1]?.Rate.display || ''
        const finalQueryRateAmount = b.PatientQueries[b.PatientQueries?.length - 1]?.Rate.rate || ''
        const compositePages = b.ClientFiles[0]?.pageCount || ''
        const billedQueryRate = b.PatientQueries?.find(q => q.Rate.rate > 0)?.Rate.display || ''
        const billedQueryAmount = b.PatientQueries?.find(q => q.Rate.rate > 0)?.Rate.rate || ''
        const billedQueryReturn = b.PatientQueries?.find(q => q.Rate.rate > 0)?.DataSourcePatientQuery?.recordCount || ''

        return {
            id,patientRate,lastName,firstName, createdAt
            ,birthDate,intialQueryReturn,intialQueryRate
            ,intialQueryRateAmount,finalQueryReturn,finalQueryRate
            ,finalQueryRateAmount,compositePages
            ,billedQueryAmount, billedQueryRate, billedQueryReturn, batchId
        }
    })
    
    return batchLines

}

const BatchReport = props => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        batchId: '',
        rate: ''
    })

    const { batch, rateOptions } = useSelector(ReportState)
    const { batchId, rate } = state
    let batchLineItems

    if(batch) batchLineItems = prepareLineItems(batch)

    useEffect(() => {
        dispatch(loadRateOptionsAsync())
    }, [])

    const onChange = e => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const setRate = e =>{
        const val = rateOptions.filter(r => r.display === e.target.innerHTML)
        setState({ ...state, rate: val[0].id })
    }

    const onClick = async e => {
        console.log(batchId, rate)
        dispatch(getBatchReportAsync({batchId, rateId:rate}))
    }

    //Defines Data Grid columns
    const columnsFormat = [
        {field: 'patientRate', headerName: 'Patient Rate', width: 200,
            renderCell: (params) => {
                return params.row.patientRate
            }},
        {field: 'batchId', headerName: 'Batch ID', width: 300,
            renderCell: (params) => {
                return params.row.batchId
            }},
        {field: 'lastName', headerName: 'Last Name', width: 200,
            renderCell: (params) => {
                return params.row.lastName
            }},
        {field: 'firstName', headerName: 'First Name', width: 200,
            renderCell: (params) => {
                return params.row.firstName
            }},
        {field: 'createdAt', headerName: 'Date Created', width: 150,
            renderCell: (params) => {
                if(!params.row.createdAt || params.row.createdAt === '') return '' 
                return moment(params.row.createdAt).format('YYYY-MM-DD')
            }},
        {field: 'birthDate', headerName: 'DOB', width: 150,
            renderCell: (params) => {
                return params.row.birthDate
            }},
        {field: 'intialQueryReturn', headerName: 'Intial Query Return', width: 150,
            renderCell: (params) => {
                return fNumber(params.row.intialQueryReturn)
            }},
        {field: 'intialQueryRate', headerName: 'Intial Query Rate', width: 200,
            renderCell: (params) => {
                return params.row.intialQueryRate
            }},
        {field: 'finalQueryReturn', headerName: 'Final Query Return', width: 150,
            renderCell: (params) => {
                return fNumber(params.row.finalQueryReturn)
            }},
        {field: 'finalQueryRate', headerName: 'Final Query Rate', width: 200,
            renderCell: (params) => {
                return params.row.finalQueryRate
            }},
        {field: 'billedQueryReturn', headerName: 'Billed Query Return', width: 150,
            renderCell: (params) => {
                return params.row.billedQueryReturn === '' ? '' : fNumber(params.row.billedQueryReturn)
            }},
        {field: 'billedQueryRate', headerName: 'Billed Query Rate', width: 200,
            renderCell: (params) => {
                return params.row.billedQueryRate
            }},
        {field: 'billedQueryAmount', headerName: 'Billed Query Amount', width: 150,
            renderCell: (params) => {
                return params.row.billedQueryAmount === '' ? '' : fCurrency(params.row.billedQueryAmount) 
            }},
        {field: 'compositePages', headerName: 'Composite Pages', width: 150,
            renderCell: (params) => {
                return fNumber(params.row.compositePages)
            }},
    ]

    const columns = React.useMemo( () =>
        columnsFormat.map((col) => {
            return {
            ...col,
            filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains' || operator.value === 'equals',
            ),
            };
        }),
        [columnsFormat],
    );

               if(rateOptions) { return (
                    <Fragment>
                        <h1>Batch Report</h1>
                        <div>
                            <Grid item xs={12} sm={2}>
                                <Label text={`Please enter a batch number or select a rate code to view patient details.`}  />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    type='text'
                                    id='batchId'
                                    name='batchId'
                                    label='Batch ID'
                                    value={batchId}
                                    onChange={onChange}
                                    variant='outlined'
                                    size={'small'}
                                />
                                <FormControl variant="outlined" style={{minWidth: 200, marginLeft: 2, display:''}} required>
                                    <Autocomplete
                                        // disablePortal
                                        id="rate"
                                        options={rateOptions?.map(option=>option.display)}
                                        sx={{ width: 300 }}
                                        size={'small'}
                                        onChange={setRate}
                                        isOptionEqualToValue={(option, value) => option.display === value.value}
                                        renderInput={(params) =>  <TextField {...params} label='Rate'/>}
                                    />
                                </FormControl>  

                                <Button onClick={onClick} style={{marginLeft: 2, marginTop:2}} variant={'contained'} color={'primary'} >Search</Button>
                            </Grid>
                            
                                
                            
                        </div>
                        <Separator />
                        {batchLineItems && <div>
                            <div style={{ height: 650, width: '100%' }}>
                                <DataGrid
                                    // getRowId={(row) => row?.source + row.returnCount}
                                    components={{
                                        Toolbar: GridToolbar
                                    }}
                                    componentsProps={{
                                        toolbar: {
                                        csvOptions: { disableToolbarButton: false },
                                        printOptions: { disableToolbarButton: true },
                                        },
                                    }}
                                    style={{
                                        backgroundColor: 'white'
                                    }}
                                    initialState={{
                                        sorting: {
                                          sortModel: [{ field: 'lastName', sort: 'asc' }],
                                        },
                                      }}
                                    rows={batchLineItems}
                                    columns={columns}
                                    rowsPerPageOptions={[25,50,100]}   
                                />
                            </div>
                        </div>               
       }
                        
                    </Fragment>
               
                )
    }
    
    else return <Spinner />

}

export default BatchReport